import * as React from 'react'
import { Box, Heading, Text, useColorModeValue as mode } from '@chakra-ui/react'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS } from '@contentful/rich-text-types'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const BlogContent = ({ contentfulBlogPost, filteredBlogPost }) => {
  const options = {
    renderNode: {
      [BLOCKS.HEADING_3]: (_node, children) => (
        <Text fontWeight={500} fontSize='24px'>
          {children}
        </Text>
      ),
      [BLOCKS.PARAGRAPH]: (_node, children) => (
        <Text
          fontSize='20px'
          fontFamily='Circular-Loom'
          marginTop={10}
          marginBottom={10}
        >
          {children}
        </Text>
      )
    }
  }

  return (
    <>
      <Box
        maxW={{
          base: '2xl',
          md: '7xl'
        }}
        mx='auto'
        px={{
          base: '6',
          md: '8'
        }}
        marginTop={20}
      >
        <Box flex='1' justifyContent='center' display='flex'>
          <Heading
            as='h1'
            fontSize='36px'
            color={mode('black.600', 'black.300')}
            fontWeight='bold'
            letterSpacing='tight'
          >
            {contentfulBlogPost.blogTitle}
          </Heading>
        </Box>
        <Box flex='1' justifyContent='center' display='flex'>
          <Heading
            as='h1'
            fontSize='14px'
            color='#718096'
            fontWeight='bold'
            letterSpacing='tight'
          >
            Last Updated {contentfulBlogPost.createdAt}
          </Heading>
        </Box>
        <Box
          flex='1'
          justifyContent='center'
          display='flex'
          marginBottom='30px'
        >
          <GatsbyImage
            image={getImage(
              contentfulBlogPost.postPreviewImage.gatsbyImageData
            )}
          />
        </Box>
        <Box>{renderRichText(contentfulBlogPost.postContent, options)}</Box>
      </Box>
      {filteredBlogPost.length
        ? (
          <Box
            backgroundColor='#F7FAFC'
            borderTop='1px solid #E2E8F0'
            padding='100px 0px'
          >
            <Heading
              as='h1'
              fontSize={36}
              fontWeight={800}
              display='flex'
              justifyContent='center'
              paddingBottom={50}
            >
              Related posts
            </Heading>
            <Box
              maxW={{
                base: '2xl',
                md: '8xl'
              }}
              mx='auto'
              px={{
                base: '6',
                md: '0'
              }}
              display='flex'
              justifyContent='space-between'
            >
              {filteredBlogPost.map(({ node }) => (
                <Link
                  to={`/blog/${node.slug}`}
                  style={{ display: 'block !important', width: '28%' }}
                  key={node.slug}
                >
                  <Box width='100%' key={node.slug}>
                    <GatsbyImage
                      image={getImage(node.postThumbnailImage.gatsbyImageData)}
                      alt={node.slug}
                    />
                    <Text
                      color='#3182CE'
                      border='1px solid #3182CE'
                      width='174px'
                      display='flex'
                      justifyContent='center'
                      marginTop='20px'
                    >
                      {node.tag}
                    </Text>
                    <Text fontWeight={700} fontSize='20px' marginTop='20px'>
                      {node.blogTitle}
                    </Text>
                    <Text
                      color='#718096'
                      fontWeight='400'
                      fontSize='14px'
                      marginTop='20px'
                    >
                      Last Updated {node.createdAt}
                    </Text>
                    <Text
                      color='#4A5568'
                      fontWeight='400'
                      fontSize='18px'
                      marginTop='20px'
                    >
                      {node.postPreviewShortText}
                    </Text>
                  </Box>
                </Link>
              ))}
            </Box>
          </Box>
          )
        : null}
    </>
  )
}

export default BlogContent
