import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import BlogContent from "../components/Blog/BlogContent"

const BlogPost = ({ data }) => {
  const contentfulBlogPost = data.contentfulBlogPost
  const allContentfulBlogPost = data.allContentfulBlogPost.edges

  const filteredBlogPost = allContentfulBlogPost.filter(
    ({ node }) => node.slug !== contentfulBlogPost.slug
  )

  return (
    <Layout blog={true}>
      <Seo title={contentfulBlogPost.blogTitle} />
      <BlogContent
        contentfulBlogPost={contentfulBlogPost}
        filteredBlogPost={filteredBlogPost}
      />
    </Layout>
  )
}

export default BlogPost

export const query = graphql`
  query page($slug: String) {
    allContentfulBlogPost {
      edges {
        node {
          blogTitle
          slug
          tag
          postPreviewShortText
          createdAt(formatString: "MMMM DD, YYYY")
          postThumbnailImage {
            gatsbyImageData(quality: 100, placeholder: TRACED_SVG)
          }
        }
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      blogTitle
      slug
      tag
      createdAt(formatString: "MMMM DD, YYYY")
      postPreviewShortText
      postThumbnailImage {
        gatsbyImageData(quality: 100, placeholder: TRACED_SVG)
      }
      postPreviewImage {
        gatsbyImageData(quality: 100, placeholder: TRACED_SVG)
      }
      postContent {
        raw
      }
    }
  }
`
